<template>
  <div class="Login">
    <div class="logo"><img src="images/logo-black.png" width="137px" /></div>
    <div class="content">
      <div class="content-left">
        <!-- <div class="enterprise-name">
          天九共享控股集团有限公司
        </div> -->
        <div class="loginTab">
          <p :class="{ select: loginType === 1 }" @click="tabLoginType(1)">验证码登录</p>
          <span> / </span>
          <p :class="{ select: loginType === 2 }" @click="tabLoginType(2)">密码登录</p>
        </div>
        <div class="formCont">
          <el-form ref="AccountFrom" :model="account" :rules="rules">
            <div class="logininputbox">
              <div class="label">请填写手机号</div>
              <el-form-item prop="mobile" v-inputblur="account.mobile">
                <el-input type="text" v-model.trim="account.mobile"></el-input>
              </el-form-item>
            </div>
            <div class="logininputbox" v-if="showImgCode">
              <div class="label">请输入图形验证码</div>
              <el-form-item prop="imgCode" v-inputblur="account.imgCode">
                <el-input type="text" v-model="account.imgCode"></el-input>
                <div class="imgyzm"><img :src="imgCode" @click="getImgCode" width="100px" height="40px" /></div>
              </el-form-item>
            </div>
            <div class="logininputbox" v-if="loginType === 1">
              <div class="label">请输入验证码</div>
              <el-form-item prop="verifyCode" v-inputblur="account.verifyCode">
                <el-input type="text" v-model="account.verifyCode" @keyup.enter.native="yzmLogin"></el-input>
                <div class="yzmtext yzmtextblue" @click="getyzm" v-show="yzmshowhide">
                  {{ yzmtext }}
                </div>
                <div class="yzmtext" v-show="!yzmshowhide">{{ yzmTime }}s后重新获取</div>
              </el-form-item>
            </div>
            <div class="logininputbox" v-else>
              <div class="label">请输入密码</div>
              <el-form-item prop="password" v-inputblur="account.password">
                <el-input type="password" v-model="account.password" @keyup.enter.native="pwdLogin" auto-complete="new-password"></el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button type="primary" @click.native="yzmLogin" v-if="loginType === 1" style="font-size: 18px">登录</el-button>
              <el-button type="primary" @click.native="pwdLogin" v-else style="font-size: 18px">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- <div class="seeagreement">
          <span @click="showAgreement">隐私政策</span>
        </div> -->
      </div>
      <div class="content-right">
        <img src="/images/rightbg.png" width="" />
        <p>让奋斗者幸福的自运行管理体系</p>
      </div>
    </div>
    <div class="bottom">
      <div>版权所有© 北京商汇云网络科技有限公司</div>
      <a href="https://beian.miit.gov.cn/">京ICP备19008993号-2 (www.jixiaoyun.com)</a>
    </div>
    <!-- <el-dialog :visible.sync="agreementVisible" width="800px">
      <div class="agreementbox">
        <div class="tit">{{ agreementTitle }}</div>
        <div v-html="agreementContent" class="cont"></div>
      </div>
    </el-dialog> -->
    <!-- 设置密码弹框 -->
    <el-dialog title="请设置密码" :visible.sync="setPasswordDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="400px">
      <el-form :model="setPasswordForm" :rules="setPasswordRules" ref="setPasswordForm" label-width="0" class="set-password">
        <p>账号：{{ this.account.mobile }}</p>
        <el-form-item prop="password">
          <div class="inputbox">
            <span class="iconfont iconpassword"></span>
            <el-input type="password" placeholder="请设置密码" v-model="setPasswordForm.password"></el-input>
          </div>
        </el-form-item>
        <div class="btn-box">
          <el-button type="primary" style="width:100%" @click="setPassword">确认</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 切换企业 -->
    <el-dialog title="选择企业" :visible.sync="changeCompanyDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="380px">
      <ul class="changecompany">
        <li v-for="(item, index) in companyList" :key="index">
          <el-radio v-model="companyRadio" :label="item.companyId">{{ item.companyName }}</el-radio>
        </li>
      </ul>
      <div style="text-align: center">
        <el-button type="primary" @click="submitChangeCompany" style="width:200px">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { login, getCode, secret, editPassword, changeCompany, getAgreement, getImgCode, isTojoy } from '@/api/api_public'
import security from '@/utils/security.min'
import { passwdReg } from '@/utils/index.js'
import { checkMobile } from '@/utils'
export default {
  name: 'Login',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!passwdReg.test(value)) {
        callback(new Error('6-16个字符，包含字母、数字、标点任意2种组合'))
      } else {
        callback()
      }
    }
    return {
      secret: null,
      loginType: 1,
      account: {
        mobile: '',
        verifyCode: '',
        password: '',
        imgCode: null
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        imgCode: [{ required: true, message: '请输入图形验证码', trigger: 'blur' }],
        verifyCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      yzmtext: '获取验证码',
      yzmTime: 0,
      yzmshowhide: true,
      imgId: null,
      imgCode: null,
      showImgCode: false,
      agreementVisible: false,
      agreementTitle: '',
      agreementContent: '',
      setPasswordDialog: false,
      setPasswordForm: {
        password: ''
      },
      setPasswordRules: {
        password: [{ validator: validatePass, trigger: 'blur' }]
      },
      changeCompanyDialog: false,
      companyList: [],
      companyRadio: Number(localStorage.getItem('selectCompanyId')),
      userInfo: {}
    }
  },
  mounted() {
    secret().then(res => {
      if (res && res.code === '000000') {
        this.secret = res.data
      }
    })
  },
  methods: {
    tabLoginType(type) {
      if (this.$refs['AccountFrom']) {
        this.$refs['AccountFrom'].clearValidate()
      }
      this.loginType = type
      this.showImgCode = false
    },
    getyzm() {
      if (this.account.mobile != '') {
        const reg = /^1\d{10}$/
        if (reg.test(this.account.mobile)) {
          let yzmParams = {
            mobile: this.account.mobile,
            imgCode: this.account.imgCode,
            imgId: this.imgId
          }
          getCode(yzmParams).then(result => {
            if (result && result.code === '000000') {
              this.$msgSuccess('发送成功')
              this.countDown()
            } else if (result.code === '001113' || result.code === '001114') {
              this.showImgCode = true
              this.getImgCode()
            }
          })
        } else {
          this.$msgError('手机号格式不正确')
        }
      } else {
        this.$msgError('请输入手机号')
      }
    },
    getImgCode() {
      getImgCode().then(result => {
        if (result && result.code === '000000') {
          this.imgCode = result.data.imgCode
          this.imgId = result.data.imgId
        }
      })
    },
    countDown() {
      this.yzmshowhide = false
      this.yzmTime = 59
      let yzmtimer = setInterval(() => {
        this.yzmTime--
        if (this.yzmTime <= 0) {
          this.yzmshowhide = true
          this.yzmtext = '重新获取验证码'
          clearInterval(yzmtimer)
        }
      }, 1000)
    },
    handleLogin(params) {
      login(params).then(result => {
        if (result && result.code === '000000') {
          let data = result.data
          localStorage.setItem('loginData', JSON.stringify(data))
          localStorage.setItem('token', data.token)
          this.userInfo = data
          if (data.isPasswordSet == 0) {
            this.setPasswordDialog = true
          } else if (data.companyList && data.companyList.length > 0) {
            this.companyList = data.companyList
            this.changeCompanyDialog = true
          } else {
            this.$router.push({ path: '/' })
          }
        } else if (result.code === '001108' || result.code === '001113' || result.code === '001114') {
          this.showImgCode = true
          this.getImgCode()
        }
      })
    },
    pwdLogin() {
      this.$refs.AccountFrom.validate(valid => {
        if (valid) {
          let loginParams = { mobile: this.account.mobile, password: this.account.password, imgCode: this.account.imgCode, imgId: this.imgId }
          loginParams.password = security.encrypty(this.secret.modulu, this.secret.exponent, loginParams.password)
          this.handleLogin(loginParams)
        }
      })
    },
    yzmLogin() {
      this.$refs.AccountFrom.validate(valid => {
        if (valid) {
          let loginParams = { mobile: this.account.mobile, verifyCode: this.account.verifyCode }
          this.handleLogin(loginParams)
        }
      })
    },
    async getAgreementCont() {
      let params = {
        onlyFlag: 'AGREENMENT'
      }
      let res = await getAgreement(params)
      if (res && res.code === '000000') {
        this.agreementContent = res.data.content
        this.agreementTitle = res.data.name
      }
    },
    showAgreement() {
      this.getAgreementCont()
      this.agreementVisible = true
    },
    //设置密码
    setPassword() {
      this.$refs.setPasswordForm.validate(valid => {
        if (valid) {
          let params = {
            password: this.setPasswordForm.password
          }
          params.password = security.encrypty(this.secret.modulu, this.secret.exponent, params.password)
          editPassword(params).then(res => {
            if (res && res.code === '000000') {
              this.setPasswordDialog = false
              this.userInfo = JSON.parse(localStorage.getItem('loginData'))
              if (this.userInfo.companyList && this.userInfo.companyList.length > 0) {
                this.companyList = this.userInfo.companyList
                this.changeCompanyDialog = true
              } else {
                this.$router.push({ path: '/' })
              }
            }
          })
        }
      })
    },
    //切换企业
    async submitChangeCompany() {
      if (this.companyRadio) {
        let params = {
          companyId: this.companyRadio
        }
        let res = await changeCompany(params)
        if (res && res.code === '000000') {
          this.changeCompanyDialog = false
          let data = res.data
          localStorage.setItem('loginData', JSON.stringify(data))
          localStorage.setItem('token', data.token)
          localStorage.setItem('selectCompanyId', this.companyRadio)
          this.$router.push({ path: '/' })
        }
        let resData = await isTojoy({})
        console.log('🚀 ~ file: Login.vue:307 ~ submitChangeCompany ~ resData:', resData)
        //是否是天九存入缓存
        localStorage.setItem('isTojoy', resData.data)
      } else {
        this.$msgError('请选择企业')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Login {
  width: 100%;
  height: 100%;
  background: url('../assets/images/bg.png') #cccccc no-repeat;
  background-size: cover;
  position: relative;
  min-width: 1360px;
  .logo {
    position: absolute;
    top: 30px;
    left: 40px;
    z-index: 1;
  }
  .bottom {
    width: 100%;
    font-size: 14px;
    color: #999999;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    a {
      color: #999999;
    }
  }
  .content {
    width: 1200px;
    height: 600px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.09);
    border: 1px solid rgba(0, 0, 0, 0.03);
    display: flex;
    .content-left {
      width: 670px;
      height: 100%;
      background-color: #fff;
      padding: 66px 80px 0;
      box-sizing: border-box;
      .enterprise-name {
        font-size: 18px;
        color: #333333;
        line-height: 24px;
      }
      .loginTab {
        margin: 60px 0 40px;
        font-size: 14px;
        font-weight: 400;
        color: $grey;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        .select {
          font-size: 24px;
          font-weight: 600;
          color: $lightblack;
        }
        span {
          margin: 0 5px;
          line-height: 16px;
        }
      }
      /deep/input {
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 0;
        color: #000000;
        background: none;
      }
      .el-button--primary {
        width: 100%;
        height: 50px;
        margin-top: 10px;
      }
      .logininputbox {
        position: relative;
        padding-top: 20px;
        .label {
          position: absolute;
          left: 0;
          top: 27px;
          font-size: 14px;
          color: #999999;
          transition: all 0.3s;
        }
      }
      .logininputboxdh {
        .label {
          top: 7px;
          font-size: 12px;
        }
      }
      .seeagreement {
        color: $grey;
        font-size: 14px;
        span {
          color: $grey;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .yzmtext {
        background: #fff;
        padding: 0 0 0 20px;
        line-height: 30px;
        position: absolute;
        font-size: 16px;
        bottom: 6px;
        right: 2px;
        color: #999999;
      }
      .yzmtextblue {
        color: $blue;
        cursor: pointer;
      }
      .imgyzm {
        position: absolute;
        right: 2px;
        bottom: 4px;
        img {
          display: block;
        }
      }
    }
    .content-right {
      width: 670px;
      height: 100%;
      text-align: center;
      padding: 83px 0 0;
      background: #e6f3ff;
      img {
        margin: 0 auto;
        width: 480px;
      }
      p {
        font-size: 18px;
        line-break: 18px;
        color: #afd2f8;
        letter-spacing: 15px;
        margin-top: 60px;
      }
    }
  }
  .set-password {
    p {
      margin-bottom: 20px;
    }
    .inputbox {
      position: relative;
      .iconpassword {
        position: absolute;
        left: 15px;
        top: 0px;
        z-index: 1;
        color: #ccc;
      }
      /deep/input {
        padding-left: 40px;
      }
    }
    .btn-box {
      text-align: center;
      margin-top: 20px;
    }
  }
  .changecompany {
    margin-bottom: 30px;
    li {
      margin-bottom: 15px;
    }
  }
  .agreementbox {
    .title {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      margin-bottom: 20px;
    }
    .cont {
      height: auto;
      max-height: 500px;
      overflow-y: scroll;
    }
  }
}
</style>
